<template>
  <div class="white--text grey lighten-3 pa-0">
    <simple-header
      hasLeftChild
      :routes="[{ title: 'خانه', url: '/' }, { title: 'درباره ما' }]"
      title="درباره ما"
    >
      <v-icon dark size="150" class="float-left">mdi-information</v-icon>
    </simple-header>
    <v-container>
      <v-card class="rounded-xl moholand-shadow">
        <v-card-text class="pa-8 black--text">
          <v-row justify="center">
            <v-col cols="12" md="7" class="text-justify">
              <h3>
                موهولند بعنوان اولین وب‌ سایت جامع ارائه و فروش فایل‌های آماده و
                لایه باز موهو، هفتم خرداد 1398 فعالیت خود را شروع کرد و
                امیدواریم با کمک همه دوستان و همراهان، فایل‌های با کیفیت بالا و
                کاربردی برای رشد صنعت انیمیشن کشور داشته باشیم. هدف ما این است
                که هر شخصی که علاقمند به ساخت انیمیشن می باشد، به آسانی بتواند
                شروع به ساخت انیمیشن کند و بتواند به راحتی تیم بسازد و پیشرفت
                کند.
              </h3>
            </v-col>
            <v-col cols="12" md="5" class="text-center">
              <v-avatar tile size="200" class="my-16">
                <v-img :src="require('@/assets/img/box-logo-FA.png')" contain />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MyAxios from "@/constants/MyAxios";
import SimpleHeader from "../../components/SimpleHeader.vue";
import MyDataTable from "../../components/MyDataTable.vue";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import { Constants } from "../../constants/GlobalConstants";

export default {
  name: "Payment",
  components: {
    SimpleHeader,
    MyDataTable,
    VFormBase,
  },
  data() {
    return {
      loading: false,
      model: {
        email: "",
        cellphone: "",
        name: "",
        desc: "",
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "نام و نام خانوادگی",
          rules: [Constants.rules.required],
        },
        email: {
          type: "CustomInput",
          label: "ایمیل شما",
          rules: [Constants.rules.required, Constants.rules.email],
          // col: 9,
        },
        cellphone: {
          type: "CustomInput",
          label: "موبایل شما",
          rules: [Constants.rules.required, Constants.rules.cellphone],
        },
        desc: {
          type: "CustomInput",
          inputType: "textarea",
          label: "توضیحات",
          rules: [Constants.rules.required],
        },
      },
    };
  },
  created() {},
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        if (!this.loading) this.loading = true;

        let data = formPostPrepare(this.model);
        MyAxios.post("contacts/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: response.data.msg,
              type: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: error.response.data.msg,
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.sp-img {
  /* width: 20em;
    height: 20em; */
  max-width: 150px;
  max-height: 100px;
}
</style>
